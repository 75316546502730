import React from 'react';
import { useGetUserInfo } from './services/UserQuery';

interface IInitDatadogs {
  children: React.ReactNode;
}

function InitDatadogs({ children }: IInitDatadogs) {
  useGetUserInfo();
  return <div>{children}</div>;
}

export default InitDatadogs;
