import { Global } from '@emotion/react';
import {
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary';
import { useLayoutEffect } from 'react';
import { getQueryClient } from './lib/request';
import Router from './routes';
import { GlobalStyles } from './styles/GlobalStyles';
import useGetToken from './hooks/useGetToken';
import { Loading } from './components/common';
import ErrorPage from './pages/Error/ErrorPage';
import { isMobile } from './lib/bridgeUtils';
import { setDatadogRequestToken } from './lib/utils';

function App() {
  const { reset } = useQueryErrorResetBoundary();
  const [isGetToken] = useGetToken();

  useLayoutEffect(() => {
    if (!isMobile.any()) setDatadogRequestToken();
  }, []);

  if (!isGetToken) return <Loading />; // 토큰 받아 오는중
  return (
    <RecoilRoot>
      <QueryClientProvider client={getQueryClient()}>
        <BrowserRouter>
          <Global styles={GlobalStyles} />
          <ErrorBoundary onReset={reset} fallbackRender={ErrorPage}>
            <Router />
          </ErrorBoundary>
        </BrowserRouter>
        <ToastContainer
          transition={Slide}
          closeButton={false}
          draggable={false}
          limit={1}
        />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
