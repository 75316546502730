import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getSessionToken } from './utils';

export const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0, // 실패시 재호출 횟수
        suspense: true,
        useErrorBoundary: true, // useQuery 에서 throw Error 체크
        networkMode: 'offlineFirst', // 네트워크 연결 없을 시에도 쿼리동작가능
      },
      mutations: {
        useErrorBoundary: true,
        networkMode: 'offlineFirst',
      },
    },
    // 쿼리에 대한 성공, 실패 전처리
    queryCache: new QueryCache({
      onError: (error, query) => {
        // TODO: example
        if (error.status === 401) {
          console.log('refresh token');
        }
        if (query.state.data !== undefined) {
          console.log('wrong!');
        }
        console.log('error...', error, query);
      },
      onSuccess: (data) => {
        console.log('success', data);
      },
    }),
    // 뮤테이션에 대한 성공, 실패 전처리
    mutationCache: new MutationCache({
      onError: () => {},
      onSuccess: () => {},
    }),
  });
};

export const client = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    if (!config?.headers) {
      console.error(
        `Expected 'config' and 'config.headers' not to be undefined`,
      );
      return;
    }
    const token = getSessionToken();
    if (!token) throw new Error('No token found');
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const get = async (url, params = {}) => {
  const result = await client.get(url, {
    params,
  });
  return result.data;
};

export const post = async (url, payload) => {
  const result = await client.post(url, payload);
  return result.data;
};

export const put = async (url, payload) => {
  const result = await client.put(url, payload);
  return result.data;
};

// delete 라는 메서드 명을 사용할 수 없음 => delete 라는 연산자가 존재하기 때문에
export const axiosDelete = async (url) => {
  const result = await client.delete(url);
  return result.data;
};
