import styled from '@emotion/styled';
import { ReactComponent as LoadingSvg } from '@src/assets/img/icon-loadingSpinner.svg';
import { colors } from '@src/styles/theme';

const LoadingBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1024;
  background-color: ${colors.black_03_floating};
  opacity: 0.5;
`;

const LoadingSpinner = styled(LoadingSvg)`
  z-index: 1025;
  animation: spinner 0.8s ease infinite;
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export { LoadingBackground, LoadingSpinner };
