import { css } from '@emotion/react';
import { colors } from './theme';

export const ToastStyles = css`
  .Toastify__toast {
    min-height: 4.8rem;
    font-size: 1.6rem;
  }

  .dashboard-toast {
    margin-top: 4rem;
  }

  .Toastify__toast-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
    width: 100%;

    & div {
      color: ${colors.white_01_basic};
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .Toastify__toast-theme--dark {
    background-color: ${colors.black_03_floating};
  }

  .Toastify__toast {
    width: 34rem;
    height: 2.3rem;
    border-radius: 0.6rem;
  }
  .Toastify__toast-container--bottom-center {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    z-index: 0;
  }
  .Toastify__toast-container--top-center {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    z-index: 50;
    opacity: 0.65;
  }
`;
