import { datadogRum } from '@datadog/browser-rum';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

export const TODAY = new Date();

export const BASE_URL: string = import.meta.env.VITE_REACT_APP_BASE_URL || '';
export const WEB_URL: string = import.meta.env.VITE_WEB_URL;
export const addBaseUrl = (url: string) => `${BASE_URL}${url}`;

// # get day - 0요일
const week = ['일', '월', '화', '수', '목', '금', '토'];
export const handleGetDay = (date: string) => {
  const day = new Date(date).getDay();
  return week[day];
};

// # get date - 00일
export const handleGetDate = (date: string) => {
  return new Date(date).getDate();
};

function addFormat(value: number) {
  return value >= 10 ? value : `0${value}`;
}
// # return FORMAT: 2022-01-02
export const toStringFormat = (date: string, delimiter = '-') => {
  const getDate = new Date(date);
  const year = getDate.getFullYear();
  const month = addFormat(getDate.getMonth() + 1);
  const day = addFormat(getDate.getDate());
  return [year, month, day].join(delimiter);
};

export const getYearMonthDay = (date: Date = new Date()) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return { year, month, day };
};

export const isSameDay = (day1: Date, day2: Date) => {
  return (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  );
};

const FORMAT = 'yyyy-MM-dd';

export function getFormattedDate(baseDate: Date, givenFormat: string = FORMAT) {
  return format(baseDate, givenFormat, { locale: ko });
}

export const getEndDate = (startDate: string) => {
  const date = new Date(Date.parse(startDate) + 6 * 24 * 60 * 60 * 1000);
  return getFormattedDate(date);
};

export const getNumberOfMonth = (startDate: string) => {
  const dateFrom = new Date(startDate);
  const currentDate = dateFrom.getDate();

  // 이번 달 1일로 지정
  const startOfMonth = new Date(dateFrom.setDate(1));

  // 이번 달 1일이 무슨 요일인지 확인
  const weekDay = startOfMonth.getDay(); // 0: Sun ~ 6: Sat

  // ((요일 - 1) + 해당 날짜) / 7일로 나누기 = N 주차
  return Math.floor((weekDay - 1 + currentDate) / 7 + 1);
};

export const getStartToEndFormat = (
  startDate: Date,
  endDate: Date,
  includeYear: boolean,
) => {
  const {
    year: startYear,
    month: startMonth,
    day: startDay,
  } = getYearMonthDay(startDate);
  const {
    year: endYear,
    month: endMonth,
    day: endDay,
  } = getYearMonthDay(endDate);

  const startFormat = `${startMonth}월 ${startDay}일`;
  const endFormat = `${endMonth}월 ${endDay}일`;

  if (!includeYear) return `${startFormat} - ${endFormat}`;
  return `${startYear}년 ${startFormat} - ${endYear}년 ${endFormat}`;
};

export function parseToDate(date: string) {
  return parseISO(date);
}

export function numberWithCommas(value: string | number | undefined) {
  if (value !== '-') {
    let convertedValue = Number(Number(value).toFixed(1));
    convertedValue = Number.isInteger(convertedValue)
      ? Math.floor(convertedValue)
      : convertedValue;
    return convertedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
}

export function calculatePoint(num: number, decimalPlace = 10) {
  const result = Math.floor(num * decimalPlace) / decimalPlace;
  return result;
}

export const setSessionToken = (token: string) => {
  sessionStorage.setItem('ksso-token', `Bearer ${token}`);
};

export const getSessionToken = () => {
  return sessionStorage.getItem('ksso-token') as string;
};

export const setDatadogRequestToken = () => {
  if (import.meta.env.MODE !== 'production') {
    datadogRum.setGlobalContext({
      requestToken: sessionStorage.getItem('ksso-token'),
    });
  }
};
