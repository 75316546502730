import React from 'react';
import {
  ModalBackground,
  ModalContainer,
  Contents,
  ModalButtonContainer,
} from './CustomModal.style';

interface ICustomModal {
  cancelButton?: boolean;
  cancelText?: string;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  children: React.ReactNode;
}

export default function CustomModal({
  cancelButton,
  cancelText = '취소',
  okText = '확인',
  onCancel,
  onOk,
  children,
}: ICustomModal) {
  return (
    <ModalBackground>
      <ModalContainer>
        <Contents>{children}</Contents>
        <ModalButtonContainer>
          {cancelButton && (
            <button type="button" className="cancel" onClick={onCancel}>
              {cancelText}
            </button>
          )}
          <button type="button" className="confirm" onClick={onOk}>
            {okText}
          </button>
        </ModalButtonContainer>
      </ModalContainer>
    </ModalBackground>
  );
}
