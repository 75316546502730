import React, { Suspense, lazy, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import InitDatadogs from './InitDatadogs';

export interface ISubRoute {
  key: string;
  path: string;
  element: React.ReactNode;
}

export interface IRouteList {
  key: string;
  path: string;
  element: React.ReactNode;
  sub?: ISubRoute[];
}

const Questionnaires = lazy(
  () => import('./pages/Questionnaires/QuestionnairesPage'),
);
const QuestionResult = lazy(
  () => import('./pages/QuestionResult/QuestionResultPage'),
);
const ManageGoal = lazy(() => import('./pages/ManageGoal/ManageGoalPage'));
const DashBoard = lazy(() => import('./pages/DashBoard/DashBoardPages'));

const Mission = lazy(() => import('./pages/Mission/MissionPage'));

const Reward = lazy(() => import('./pages/Reward/RewardPage'));
const HealthContent = lazy(
  () => import('./pages/HealthContents/HealthContentsPage'),
);

const RemindList = lazy(() => import('./pages/Remind/RemindListPage'));
const RemindWrite = lazy(() => import('./pages/Remind/RemindWritePage'));
const ReportDetailPage = lazy(() => import('./pages/Report/ReportDetailPage'));
const ReportPage = lazy(() => import('./pages/Report/ReportPage'));

const Withdrawal = lazy(() => import('./pages/Withdrawal/WithdrawalPage'));

const Guide = lazy(() => import('./styles/Guide'));

export const routeList: IRouteList[] = [
  {
    key: 'questionnaires',
    path: '/questionnaires',
    element: <Questionnaires />,
  },
  {
    key: 'questionResult',
    path: '/result',
    element: <QuestionResult />,
  },
  {
    key: 'ManageGoal',
    path: '/goal',
    element: <ManageGoal />,
  },
  {
    key: 'mission',
    path: '/mission',
    element: <Mission />,
  },
  {
    key: 'reward',
    path: '/reward',
    element: <Reward />,
  },
  {
    key: 'report',
    path: '/etc/report',
    element: <ReportPage />,
  },
  {
    key: 'reportDetail',
    path: '/etc/report/detail',
    element: <ReportDetailPage />,
  },
  {
    key: 'dashBoard',
    path: '/',
    element: <DashBoard />,
  },
  {
    key: 'healthContents',
    path: '/contents',
    element: <HealthContent />,
  },
  {
    key: 'remind',
    path: '/remind',
    element: <RemindList />,
  },
  {
    key: 'remind/write',
    path: '/remind/write',
    element: <RemindWrite />,
  },
  {
    key: 'withdrawal',
    path: '/withdrawal',
    element: <Withdrawal />,
  },
  {
    key: 'style',
    path: '/style/guide',
    element: <Guide />,
  },
];

function Router() {
  const networkError = () => new Error('Network is disabled');
  const handler = useErrorHandler();

  console.log('VITE_APP_TITLE ::  ', import.meta.env.VITE_APP_TITLE);
  useEffect(() => {
    const offlineHandler = () => handler(networkError);
    window.addEventListener('offline', offlineHandler);

    window.addEventListener('offline', offlineHandler);
    window.addEventListener('unhandledrejection', handler);

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('unhandledrejection', handler);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <InitDatadogs>
        <Routes>
          {routeList.map((route) => (
            <Route {...route} element={route.element}>
              {route.sub?.map((subRoute) => (
                <Route {...subRoute} />
              ))}
            </Route>
          ))}
        </Routes>
      </InitDatadogs>
    </Suspense>
  );
}

export default Router;
