export class KssoDate extends Date {
  constructor(...options: ConstructorParameters<typeof Date>) {
    if (options?.length === 1) {
      const arg = options[0];
      if (typeof arg === 'string') {
        super(arg.replaceAll('-', '/'));
      } else {
        super(arg);
      }
    } else {
      super(...options);
    }
  }
}
