/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IusePostRemind } from '@src/services/RemindQuery';

export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i) != null;
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
  },
  any() {
    return isMobile.Android() || isMobile.iOS();
  },
};

// 토큰 요청
export const requestToken = () => {
  console.log('requestToken :: 토큰 요청');
  if (isMobile.Android()) {
    console.log('Android 토큰 요청');
    // @ts-ignore
    return window.AnchorWire.requestToken();
  }
  if (isMobile.iOS()) {
    console.log('iOS 토큰 요청');
    // @ts-ignore
    return window.webkit.messageHandlers.requestToken.postMessage({});
  }
};

// 웹뷰 이탈
export const exit = () => {
  console.log('exit :: 웹뷰 이탈');
  if (isMobile.Android()) {
    console.log('Android 웹뷰 이탈');
    // @ts-ignore
    return window.AnchorWire.exit();
  }
  if (isMobile.iOS()) {
    console.log('iOS 웹뷰 이탈');
    // @ts-ignore
    return window.webkit.messageHandlers.exit.postMessage({});
  }
};

// 초기설문 중도이탈 시 로그아웃
export const logout = () => {
  console.log('logout :: 로그아웃');
  if (isMobile.Android()) {
    console.log('Android 로그아웃');
    // @ts-ignore
    return window.AnchorWire.logout();
  }
  if (isMobile.iOS()) {
    console.log('iOS 로그아웃');
    // @ts-ignore
    return window.webkit.messageHandlers.logout.postMessage({});
  }
};

// 초기설문을 완료하였음을 네이티브에 알림
export const completeBasicSurvey = () => {
  console.log('completeBasicSurvey :: 초기설문 완료');
  if (isMobile.Android()) {
    console.log('Android 초기설문 완료');
    // @ts-ignore
    return window.AnchorWire.completeBasicSurvey();
  }
  if (isMobile.iOS()) {
    console.log('iOS 초기설문 완료');
    // @ts-ignore
    return window.webkit.messageHandlers.completeBasicSurvey.postMessage({});
  }
};

// 외부 브라우저로 이동이 필요할 시
export const externalLink = (url: string) => {
  console.log('externalLink :: 외부 브라우저로 이동');
  if (isMobile.Android()) {
    console.log(`Android 외부 브라우저로 이동 : ${url}`);
    // @ts-ignore
    return window.AnchorWire.externalLink(url);
  }
  if (isMobile.iOS()) {
    console.log(`iOS 외부 브라우저로 이동 : ${url}`);
    // @ts-ignore
    return window.webkit.messageHandlers.externalLink.postMessage({ url });
  }
};

// 웹뷰의 새로운 페이지로 이동 시
export const newPage = (url: string) => {
  console.log('newPage :: 웹뷰의 새로운 페이지로 이동');
  if (isMobile.Android()) {
    console.log(`Android 웹뷰의 새로운 페이지로 이동 : ${url}`);
    // @ts-ignore
    return window.AnchorWire.newPage(url);
  }
  if (isMobile.iOS()) {
    console.log(`iOS  웹뷰의 새로운 페이지로 이동 : ${url}`);
    // @ts-ignore
    return window.webkit.messageHandlers.newPage.postMessage({
      url,
    });
  }
};

// 네이티브의 특정 메뉴로 이동 시
export const movePage = (menuId: string, selectedDate = '', contentId = '') => {
  console.log('movePage :: 네이티브 특정 메뉴로 이동');
  if (isMobile.Android()) {
    console.log(
      `Android 네이티브 특정 메뉴로 이동 : ${menuId},${selectedDate},${contentId}`,
    );
    // @ts-ignore
    return window.AnchorWire.movePage(menuId, selectedDate, contentId);
  }
  if (isMobile.iOS()) {
    console.log(
      `iOS  네이티브 특정 메뉴로 이동 : ${menuId}${selectedDate}${contentId}`,
    );
    // @ts-ignore
    return window.webkit.messageHandlers.movePage.postMessage({
      menuId,
      selectedDate,
      contentId,
    });
  }
};

// 나의 다짐 공유를 위한 나의 다짐 카드 데이터 전달
export const shareMMS = (mmsData: IusePostRemind) => {
  console.log('shareMMS :: 나의 다짐 공유');
  const jsonString = JSON.stringify(mmsData);

  if (isMobile.Android()) {
    console.log(`Android 나의 다짐 공유 :`, mmsData);
    // @ts-ignore
    return window.AnchorWire.shareMMS(jsonString);
  }
  if (isMobile.iOS()) {
    console.log(`iOS 나의 다짐 공유 :`, mmsData);
    // @ts-ignore
    return window.webkit.messageHandlers.shareMMS.postMessage({
      mmsData: jsonString,
    });
  }
};
