import { datadogRum } from '@datadog/browser-rum';
import { checkDataAndSendError } from '@src/lib/dataCheckUtils';
import { get } from '@src/lib/request';
import { getUserInfoTypeObj } from '@src/lib/typeObjects';
import { IUserInfo } from '@src/lib/types';
import { useQuery } from '@tanstack/react-query';

const baseUrl = '/user';

export const useGetUserInfo = () => {
  const apiUrl = `${baseUrl}/profile`;

  return useQuery<IUserInfo>(['userInfo'], () => get(apiUrl), {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (res) => {
      if (import.meta.env.MODE === 'production') return;
      checkDataAndSendError(res, getUserInfoTypeObj, apiUrl);

      const dgUser = datadogRum.getUser();
      if (!dgUser.userId)
        datadogRum.setUser({
          userId: res.user_id,
          loginId: res.login_id,
          name: res.name,
          registDttm: res.registration_dttm,
        });
    },
  });
};
