import React from 'react';
import ReactDOM from 'react-dom/client';
// import { datadogRum, RumErrorEventDomainContext } from '@datadog/browser-rum';
import App from './App';
// import { ICustomError } from './lib/CustomError';
import { KssoDate } from './KssoDate';
/*
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';


Sentry.init({
  dsn: 'https://c04274037dca452b91a09489a929b8ee@o1373541.ingest.sentry.io/6717903',
  integrations: [new BrowserTracing()],
  release: '1.1',
  enabled: import.meta.env.PROD,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

// if (import.meta.env.MODE !== 'production') {
//   datadogRum.init({
//     applicationId: 'ed86d45f-01f1-4c50-9f12-1e3fcea57150',
//     clientToken: 'pubca35e7f2c9dff537c948eb1902caec59',
//     site: 'datadoghq.com',
//     service: 'ksso_frontend',
//     env: 'prod',
//     // Specify a version number to identify the deployed version of your application in Datadog
//     version: '1.0.0',
//     sampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
//     allowedTracingOrigins: [import.meta.env.VITE_REACT_APP_BASE_URL],
//     beforeSend: (event, context) => {
//       if (event.type === 'error') {
//         const errContext = context as RumErrorEventDomainContext;
//         const error = errContext.error as ICustomError;
//         const isCustomError = error.statusCode;

//         if (event.context && isCustomError) {
//           const { statusCode, apiUrl, requestType, errorType, reason, data } =
//             error;
//           // eslint-disable-next-line no-param-reassign
//           event.context = {
//             ...event.context,
//             statusCode,
//             requestType,
//             apiUrl,
//             errorType,
//             reason,
//             data,
//             date: new Date(),
//           };
//         }
//       }
//     },
//   });

//   datadogRum.startSessionReplayRecording();
// }

Object.assign(window, {
  Date: KssoDate,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
