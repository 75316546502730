import { LoadingBackground, LoadingSpinner } from './Loading.styles';

function Loading() {
  return (
    <LoadingBackground>
      <LoadingSpinner />
    </LoadingBackground>
  );
}

export default Loading;
