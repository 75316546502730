import { requestToken } from '@src/lib/bridgeUtils';
import {
  getSessionToken,
  setDatadogRequestToken,
  setSessionToken,
} from '@src/lib/utils';
import { useEffect, useState } from 'react';

export default function useGetToken() {
  const [isGetToken, setIsGetToken] = useState(false);

  // @ts-ignore
  window.responseToken = (token: string) => {
    console.log('get token form native :: ', token);
    setSessionToken(token);
    setIsGetToken(true);

    setDatadogRequestToken();
  };

  useEffect(() => {
    if (!isGetToken) {
      requestToken();
    }
    if (!isGetToken && getSessionToken()) setIsGetToken(true);
  }, [isGetToken]);
  return [isGetToken];
}
