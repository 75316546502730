import CustomModal from '@src/components/common/Modal/CustomModal';
import { exit } from '@src/lib/bridgeUtils';

function ErrorPage() {
  const handleOnOk = () => {
    exit();
  };

  return (
    <CustomModal onOk={handleOnOk}>
      <>
        인터넷 연결이 불안정합니다. <br /> 잠시 후 다시 시도해 주세요.
      </>
    </CustomModal>
  );
}

export default ErrorPage;
